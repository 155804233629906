import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { secureApi } from "../../config";

const AddModal = ({
  showAddModal,
  setShowAddModal,
  location,
  setLocation,
  locations,
  setLocations,
}) => {
  const [locationError, setLocationError] = useState(false);
  const [locationErrorMsg, setLocationErrorMsg] = useState("");

  const handleModalClose = () => {
    setLocation("");
    setLocationError(false);
    setShowAddModal(false);
  };

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setLocation(value);
    if (value.trim() === "") {
      setLocationError(true);
      setLocationErrorMsg("Location is required");
    } else {
      setLocationError(false);
    }
  };

  const addLocation = () => {
    if (location.trim() === "") {
      setLocationError(true);
      setLocationErrorMsg("Location is required");
    } else if (locations.some((loc) => loc.location === location)) {
      setLocationError(true);
      setLocationErrorMsg("Location already exists");
    } else
      secureApi(window.localStorage.getItem("alex-med-token"))
        .post("/office-location", {
          location,
        })
        .then((response) => {
          setLocations([...locations, response.data]);
          toast.success("Office location has been added successfully!");
          handleModalClose();
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
  };
  return (
    <Dialog
      open={showAddModal}
      onClose={handleModalClose}
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
    >
      <DialogTitle>Add Location</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="location"
          label="Location"
          type="text"
          fullWidth
          variant="standard"
          value={location}
          error={locationError}
          helperText={locationError ? locationErrorMsg : ""}
          onChange={handleLocationChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="error">
          Cancel
        </Button>
        <Button onClick={addLocation} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal;
