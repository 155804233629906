import React from "react";
import {
  Stack,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import {
  AddLocation as AddLocationIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

const OfficeList = ({
  officeLocation,
  setOfficeLocation,
  locations,
  setShowAddModal,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <Stack direction="row" spacing={1}>
      <Select
        variant="standard"
        color="primary"
        size="medium"
        fullWidth
        name="clinic"
        sx={{ minWidth: "500px" }}
        value={officeLocation}
        onChange={setOfficeLocation}
      >
        {locations.length > 0 &&
          locations.map((loc) => (
            <MenuItem key={loc._id} value={loc._id}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <span>{loc.location}</span>
                <Box display="flex" gap={1}>
                  <Tooltip title="Edit">
                    <IconButton
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation(); // Prevents the MenuItem from being selected
                        handleEditClick(loc._id);
                      }}
                      color="secondary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation(); // Prevents the MenuItem from being selected
                        handleDeleteClick(loc._id);
                      }}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </MenuItem>
          ))}
      </Select>
      <Tooltip title="Add an office location">
        <IconButton variant="outlined" onClick={() => setShowAddModal(true)}>
          <AddLocationIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default OfficeList;
