import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { secureApi } from "../../config";

const EditModal = ({
  showEditModal,
  setShowEditModal,
  facility,
  setFacility,
  facilities,
  setFacilities,
  facilityID,
}) => {
  const [facilityError, setFacilityError] = useState(false);
  const [facilityErrorMsg, setFacilityErrorMsg] = useState("");

  const handleFacilityChange = (e) => {
    const { value } = e.target;
    setFacility(value);
    if (value.trim() === "") {
      setFacilityError(true);
      setFacilityErrorMsg("Facility is required");
    } else {
      setFacilityError(false);
    }
  };

  const handleModalClose = () => {
    setFacilityError(false);
    setFacilityErrorMsg("");
    setShowEditModal(false);
  };

  const updateFacility = () => {
    const filteredFacilities = facilities.filter(
      (fac) => fac._id !== facilityID && fac.facility === facility
    );
    if (facility.trim() === "") {
      setFacilityError(true);
      setFacilityErrorMsg("Facility is required");
    } else if (filteredFacilities.length > 0) {
      setFacilityError(true);
      setFacilityErrorMsg("Facility already exists");
    } else {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .put(`/facility/${facilityID}`, {
          facility,
        })
        .then(() => {
          const updatedFacilities = facilities.map((fac) => ({
            _id: fac._id,
            facility: fac._id === facilityID ? facility : fac.facility,
          }));
          setFacilities(updatedFacilities);
          toast.success("Facility has been updated successfully!");
          setShowEditModal(false);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
  };

  return (
    <Dialog
      open={showEditModal}
      onClose={handleModalClose}
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
    >
      <DialogTitle>Edit Facility</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="facility"
          label="Facility"
          type="text"
          fullWidth
          variant="standard"
          value={facility}
          error={facilityError}
          helperText={facilityError ? facilityErrorMsg : ""}
          onChange={handleFacilityChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="error">
          Cancel
        </Button>
        <Button onClick={updateFacility} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
