import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { secureApi } from "../../config";

const EditModal = ({
  showEditModal,
  setShowEditModal,
  refer,
  setRefer,
  refers,
  setRefers,
  referID,
}) => {
  const [referError, setReferError] = useState(false);
  const [referErrorMsg, setReferErrorMsg] = useState("");

  const handleReferChange = (e) => {
    const { value } = e.target;
    setRefer(value);
    if (value.trim() === "") {
      setReferError(true);
      setReferErrorMsg("Refer is required");
    } else {
      setReferError(false);
    }
  };

  const handleModalClose = () => {
    setReferError(false);
    setReferErrorMsg("");
    setShowEditModal(false);
  };

  const updateRefer = () => {
    const filteredRefers = refers.filter(
      (ref) => ref._id !== referID && ref.refer === refer
    );
    if (refer.trim() === "") {
      setReferError(true);
      setReferErrorMsg("Refer is required");
    } else if (filteredRefers.length > 0) {
      setReferError(true);
      setReferErrorMsg("Refer already exists");
    } else {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .put(`/refer/${referID}`, {
          refer,
        })
        .then(() => {
          const updatedRefers = refers.map((ref) => ({
            _id: ref._id,
            refer: ref._id === referID ? refer : ref.refer,
          }));
          setRefers(updatedRefers);
          toast.success("Refer has been updated successfully!");
          setShowEditModal(false);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
  };

  return (
    <Dialog
      open={showEditModal}
      onClose={handleModalClose}
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
    >
      <DialogTitle>Edit Refer</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="refer"
          label="Refer"
          type="text"
          fullWidth
          variant="standard"
          value={refer}
          error={referError}
          helperText={referError ? referErrorMsg : ""}
          onChange={handleReferChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="error">
          Cancel
        </Button>
        <Button onClick={updateRefer} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
