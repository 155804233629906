import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { toast } from "react-toastify";
import { secureApi } from "../../config";

const DeleteModal = ({
  showDeleteModal,
  setShowDeleteModal,
  refers,
  setRefers,
  referID,
}) => {
  const handleConfirmDelete = async () => {
    try {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .delete(`/refer/${referID}`)
        .then(() => {
          setRefers(refers.filter((ref) => ref._id !== referID));
          toast.success("Refer has been deleted successfully!");
          setShowDeleteModal(false);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } catch (error) {
      console.error("Error deleting refer:", error);
    }
  };

  return (
    <Dialog
      open={showDeleteModal}
      onClose={() => setShowDeleteModal(false)}
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
    >
      <DialogTitle>Delete Refer</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this refer? This action cannot
        be undone.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setShowDeleteModal(false)}
          color="error"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmDelete}
          color="primary"
          variant="outlined"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
