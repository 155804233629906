import React from "react";
import {
  Stack,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import {
  PersonAdd as PersonAddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

const ReferList = ({
  refer,
  setRefer,
  refers,
  setShowAddModal,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <Stack direction="row" spacing={2}>
      <Select
        variant="standard"
        color="primary"
        size="medium"
        fullWidth
        name="referredBy"
        value={refer}
        onChange={setRefer}
      >
        {refers.length > 0 &&
          refers.map((ref) => (
            <MenuItem key={ref._id} value={ref._id}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <span>{ref.refer}</span>
                <Box display="flex" gap={1}>
                  <Tooltip title="Edit">
                    <IconButton
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation(); // Prevents the MenuItem from being selected
                        handleEditClick(ref._id);
                      }}
                      color="secondary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation(); // Prevents the MenuItem from being selected
                        handleDeleteClick(ref._id);
                      }}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </MenuItem>
          ))}
      </Select>
      <Tooltip title="Add refers">
        <IconButton
          variant="outlined"
          onClick={() => setShowAddModal(true)}
        >
          <PersonAddIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default ReferList;
