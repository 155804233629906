import React from "react";
import {
  Stack,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import {
  AddBusiness as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

const FacilityList = ({
  facility,
  setFacility,
  facilities,
  setShowAddModal,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <Stack direction="row" spacing={1}>
      <Select
        variant="standard"
        color="primary"
        size="medium"
        fullWidth
        name="facility"
        sx={{ minWidth: "500px" }}
        value={facility}
        onChange={setFacility}
      >
        {facilities.length > 0 &&
          facilities.map((fac) => (
            <MenuItem key={fac._id} value={fac._id}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <span>{fac.facility}</span>
                <Box display="flex" gap={1}>
                  <Tooltip title="Edit">
                    <IconButton
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation(); // Prevents the MenuItem from being selected
                        handleEditClick(fac._id);
                      }}
                      color="secondary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation(); // Prevents the MenuItem from being selected
                        handleDeleteClick(fac._id);
                      }}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </MenuItem>
          ))}
      </Select>
      <Tooltip title="Add a facility">
        <IconButton variant="outlined" onClick={() => setShowAddModal(true)}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default FacilityList;
