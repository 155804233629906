import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Paper,
  Input,
  Button,
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tabs,
  Tab,
  Box,
  Slide,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  Send as SendIcon,
  CloudUpload as CloudUploadIcon,
  Camera as CameraIcon,
} from "@mui/icons-material";
import { styled, useTheme } from "@mui/material/styles";
import Webcam from "react-webcam";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  secureApi,
  AVATAR_URL,
  MEDICAL_BILLING_PERSON,
  API_URL,
  ADMIN,
  MEDICAL_ASSISTANT,
  CHRONIC_PAIN_PATIENT,
  PERSONAL_INJURY_PATIENT,
} from "../config";
import PatientInfo from "../components/patient/patientInfo";
import ContactInfo from "../components/patient/contactInfo";
import EmergencyContact from "../components/patient/emergencyContact";
import Employment from "../components/patient/employment";
import ConditionRelatedTo from "../components/patient/conditionRelatedTo";
import InsuranceLawyerInfo from "../components/patient/insuranceLawyerInfo";
import InsuranceInformation from "../components/patient/insuranceInformation";
import Notes from "../components/patient/notes";
import Forms from "../components/patient/forms";
import Attachments from "../components/patient/attachments";
import Eligibility from "../components/patient/eligibility";
import Billing from "../components/patient/billing";
import TopNav from "../components/topnav";
import { Context } from "../context";
import axios from "axios";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Patient = () => {
  const { setLoggedIn, user } = useContext(Context);
  const { patient_id } = useParams();
  const defaultAvatarUrl = "../images/default_avatar.png";
  const [patient, setPatient] = useState({
    clinic: "",
    prefix: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    suffix: "",
    date_of_accident: dayjs("1/1/2024"),
    time_place: "",
    gender: "",
    date_of_birth: null,
    marital_status: "",
    ssn: "",
    description_of_accident: "",
    type: CHRONIC_PAIN_PATIENT,
    identifier: "",
    referredBy: "",
  });

  const [contactInfo, setContactInfo] = useState({
    preferred_contact: "",
    contact_number: "",
    home_phone: "",
    email_address: "",
    primary_address: "",
  });

  const [emergencyContact, setEmergencyContact] = useState({
    contact_name: "",
    contact_number: "",
    contact_relation: "",
  });

  const [employment, setEmployment] = useState({
    employer: "",
    business_phone: "",
    business_email: "",
    work_address: "",
  });

  const [conditionRelatedTo, setConditionRelatedTo] = useState({
    accident_type: "",
    date_of_first_visit: null,
    nf3: "",
    nf2: "",
  });

  const [insuranceLawyerInfo, setInsuranceLawyerInfo] = useState({
    insurance_company: "",
    insurance_address: "",
    auto_policy_number: "",
    claim_adjustor: "",
    claim_adjustor_phone: "",
    extension: "",
    claim: "",
    workers_compensation: "",
    carrier_case: "",
    lawyer: "",
    lawyer_phone_number: "",
    lawyer_email_address: "",
    lawyer_address: "",
  });

  const [insuranceInformation, setInsuranceInformation] = useState({
    insurance_provider: "",
    policy_number: "",
    group_number: "",
    name_of_subscriber: "",
    botox_authorization: "",
    botox_authorization_validity_dates: "",
    date_of_birth_of_subscriber: "",
    relationship_to_the_patient: "",
    address_of_subscriber: "",
    phone_number_of_subscriber: "",
  });

  const [demographics, setDemographics] = useState(null);
  const [demographicsInfo, setDemographicsInfo] = useState({});
  const [loadingDemographics, setLoadingDemographics] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const avatarInput = useRef();
  const navigate = useNavigate();

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [submitable, setSubmitable] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [doubleInputConfirmModalOpen, setDoubleInputConfirmModalOpen] =
    useState(false);
  const [painConsultNoteExist, setPainConsultNoteExist] = useState(false);
  const [painConsultNoteSigned, setPainConsultNoteSigned] = useState(false);
  const [telehealthConsultNoteExist, setTelehealthConsultNoteExist] =
    useState(false);

  const webcamRef = useRef(null);
  const [cameraDialogOpen, setCameraDialogOpen] = useState(false);
  const [captureImgSrc, setCaptureImgSrc] = useState(null);
  const [selectedCamera, setSelectedCamera] = useState("environment");

  // Forms
  const [consentForm, setConsentForm] = useState(null);
  const [hipaaForm, setHipaaForm] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCancelModalOpen = () => {
    if ((user && user.role === MEDICAL_BILLING_PERSON) || painConsultNoteSigned)
      navigate("/");
    else setCancelModalOpen(true);
  };

  const handleCancelModalClose = () => {
    setCancelModalOpen(false);
  };

  const goToPatientTable = () => {
    navigate("/");
  };

  const goToProcedureNote = () => {
    window.scrollTo(0, 0);
    navigate(`/pain-consult-note?patient_id=${patient_id}`);
  };

  const createAnotherPatient = () => {
    window.location.href = `/patient`;
  };

  const handleDemograhpicsSelect = (event) => {
    const file = event.target.files[0];
    setDemographics(file);
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.includes("image")) {
      setAvatar(file);
    } else {
      setAvatar(null);
    }
  };

  const handleAvatarRemove = () => {
    avatarInput.current.value = null;
    setAvatar(null);
  };

  const handleDemographicsUpload = async () => {
    if (demographics == null) return;
    // Create FormData object
    const formData = new FormData();
    formData.append("image", demographics);

    setLoadingDemographics(true);
    axios
      .post(`${API_URL}/get_demographics`, formData, {
        headers: {
          "Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          Authorization: `Bearer ${window.localStorage.getItem(
            "alex-med-token"
          )}`,
        },
      })
      .then((response) => {
        const demographics = response.data;
        setDemographicsInfo(demographics.demographicsInfo);
        setPatient({
          ...patient,
          ...demographics.patient,
          date_of_accident: dayjs(demographics.patient.date_of_accident),
          date_of_birth: dayjs(demographics.patient.date_of_birth),
        });
        setContactInfo({
          ...contactInfo,
          preferred_contact: demographics.contactInfo.preferred_contact,
          contact_number: demographics.contactInfo.contact_number,
          email_address: demographics.contactInfo.email_address,
          primary_address: demographics.contactInfo.primary_address,
        });
        setConditionRelatedTo({
          ...conditionRelatedTo,
          accident_type: demographics.conditionRelatedTo.accident_type,
          date_of_first_visit: dayjs(
            demographics.conditionRelatedTo.date_of_first_visit
          ),
          nf3: demographics.conditionRelatedTo.nf3,
          nf2: demographics.conditionRelatedTo.nf2,
        });
        setInsuranceLawyerInfo({
          ...insuranceLawyerInfo,
          insurance_company: demographics.insuranceLawyerInfo.insurance_company,
          insurance_address: demographics.insuranceLawyerInfo.insurance_address,
          auto_policy_number:
            demographics.insuranceLawyerInfo.auto_policy_number,
          claim_adjustor: demographics.insuranceLawyerInfo.claim_adjustor,
          claim_adjustor_phone:
            demographics.insuranceLawyerInfo.claim_adjustor_phone,
          extension: demographics.insuranceLawyerInfo.extension,
          claim: demographics.insuranceLawyerInfo.claim,
          workers_compensation:
            demographics.insuranceLawyerInfo.workers_compensation,
          carrier_case: demographics.insuranceLawyerInfo.carrier_case,
          lawyer: demographics.insuranceLawyerInfo.lawyer,
          lawyer_phone_number:
            demographics.insuranceLawyerInfo.lawyer_phone_number,
          lawyer_email_address:
            demographics.insuranceLawyerInfo.lawyer_email_address,
          lawyer_address: demographics.insuranceLawyerInfo.lawyer_address,
        });
        setLoadingDemographics(false);
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          toast.error(e.response.data.message);
        }
        setLoadingDemographics(false);
      });
  };

  const handleCancel = () => {
    navigate("/");
  };

  const handleCameraDialogOpen = () => {
    setCameraDialogOpen(true);
  };

  const handleCameraDialogClose = () => {
    setCaptureImgSrc(null);
    setCameraDialogOpen(false);
  };

  const handleCaptureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCaptureImgSrc(imageSrc);
  };

  const retake = () => {
    setCaptureImgSrc(null);
  };

  const toggleCamera = () => {
    setSelectedCamera(selectedCamera === "user" ? "environment" : "user");
  };

  const confirmCapture = () => {
    var arr = captureImgSrc.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file = new File([u8arr], "capture.jpeg", { type: mime });
    setDemographics(file);
    handleCameraDialogClose();
  };

  useEffect(() => {
    if (patient.type === CHRONIC_PAIN_PATIENT)
      setSubmitable(
        patient.clinic &&
          patient.first_name &&
          patient.last_name &&
          patient.date_of_birth &&
          patient.gender
      );
    else
      setSubmitable(
        patient.clinic &&
          patient.first_name &&
          patient.last_name &&
          patient.date_of_birth &&
          patient.date_of_accident &&
          patient.gender
      );
  }, [patient]);

  const handleSave = (doubleCheck = true) => {
    if (user && user.role === MEDICAL_BILLING_PERSON) {
      toast.error("You don't have permission to create or update patients!");
      return;
    }
    const newPatient = {
      ...patient,
      date_of_accident: patient.date_of_accident.format("MM/DD/YYYY"),
      date_of_birth: patient.date_of_birth.format("MM/DD/YYYY"),
    };

    // Create FormData object
    const formData = new FormData();
    if (avatar) formData.append("avatar", avatar);
    formData.append("patientInfo", JSON.stringify(newPatient));
    formData.append("contactInfo", JSON.stringify(contactInfo));
    formData.append("emergencyContact", JSON.stringify(emergencyContact));
    formData.append("employment", JSON.stringify(employment));

    if (patient.type === PERSONAL_INJURY_PATIENT) {
      const newConditionRelatedTo = {
        ...conditionRelatedTo,
        date_of_first_visit:
          conditionRelatedTo.date_of_first_visit.format("MM/DD/YYYY"),
      };
      formData.append(
        "insuranceLawyerInfo",
        JSON.stringify(insuranceLawyerInfo)
      );
      formData.append(
        "conditionRelatedTo",
        JSON.stringify(newConditionRelatedTo)
      );
    } else {
      formData.append(
        "insuranceInformation",
        JSON.stringify(insuranceInformation)
      );
    }
    formData.append("doubleCheck", doubleCheck);
    formData.append("demographicsInfo", JSON.stringify(demographicsInfo));

    // Make a POST request to the backend using secureApi or fetch
    if (patient_id)
      axios
        .put(`${API_URL}/patient/${patient_id}`, formData, {
          headers: {
            "Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            Authorization: `Bearer ${window.localStorage.getItem(
              "alex-med-token"
            )}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          toast.success("Patient data updated successfully!");
          window.scrollTo(0, 0);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    else {
      if (doubleCheck) {
        secureApi(window.localStorage.getItem("alex-med-token"))
          .post(`/check_create_patient`, formData)
          .then((response) => {
            const { patient_id } = response.data;
            if (patient_id) {
              navigate(`/patient/${patient_id}`);
              setSuccessModalOpen(true);
            } else {
              setDoubleInputConfirmModalOpen(true);
            }
          })
          .catch((e) => {
            console.log(e);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else {
              toast.error(e.response.data.message);
            }
          });
      } else {
        secureApi(window.localStorage.getItem("alex-med-token"))
          .post(`/patient`, formData)
          .then((response) => {
            const { patient_id } = response.data;
            navigate(`/patient/${patient_id}`);
            setSuccessModalOpen(true);
          })
          .catch((e) => {
            console.log(e);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else {
              toast.error(e.response.data.message);
            }
          });
      }
    }
  };

  useEffect(() => {
    if (patient_id) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .get(`/patient/${patient_id}`)
        .then((response) => {
          const { data: patientData } = response;
          console.log(patientData);
          const patientInfoData = {
            ...patientData.patientInfo,
            date_of_accident: dayjs(patientData.patientInfo.date_of_accident),
            date_of_birth: dayjs(patientData.patientInfo.date_of_birth),
          };
          setPatient({
            ...patientInfoData,
            identifier: patientData.identifier,
          });
          setContactInfo(patientData.contactInfo);
          setEmergencyContact(patientData.emergencyContact);
          setEmployment(patientData.employment);

          if (patientInfoData.type === PERSONAL_INJURY_PATIENT) {
            const conditionRelatedToData = {
              ...patientData.conditionRelatedTo,
              date_of_first_visit: dayjs(
                patientData.conditionRelatedTo.date_of_first_visit
              ),
            };
            setConditionRelatedTo(conditionRelatedToData);
            setInsuranceLawyerInfo(patientData.insuranceLawyerInfo);
          } else {
            const dateOfBirthOfSubscriber =
              patientData.insuranceInformation.date_of_birth_of_subscriber;
            const insuranceInformationData = {
              ...patientData.insuranceInformation,
              date_of_birth_of_subscriber: dateOfBirthOfSubscriber
                ? dayjs(
                    patientData.insuranceInformation.date_of_birth_of_subscriber
                  )
                : null,
            };
            setInsuranceInformation(insuranceInformationData);
          }

          if (patientData.avatar) {
            const url = `${AVATAR_URL}/${patientData.avatar}`;
            fetch(url)
              .then((response) => response.blob())
              .then((blob) => {
                const file = new File([blob], patientData.avatar, {
                  type: blob.type,
                });
                setAvatar(file);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
          setConsentForm(patientData.consentForm);
          setHipaaForm(patientData.hipaaForm);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else if (e.response && e.response.data) {
            toast.error(e.response.data.message);
          } else {
            toast.error(
              "Something went wrong while getting patient information."
            );
          }
        });
    } else {
      if (!user || user.role === MEDICAL_BILLING_PERSON) {
        toast.warn("You don't have permission to create a new patient!");
        navigate("/");
      }
    }
  }, [patient_id]);

  return (
    <>
      <TopNav item="patients" />
      <Stack direction="column" style={{ margin: "30px" }} spacing={2}>
        {user &&
          user.role !== MEDICAL_BILLING_PERSON &&
          !painConsultNoteExist && (
            <>
              <Stack direction="row" spacing={1}>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Select file
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*, application/pdf"
                    onChange={handleDemograhpicsSelect}
                  />
                </Button>
                <Button
                  variant="contained"
                  startIcon={<CameraIcon />}
                  onClick={handleCameraDialogOpen}
                >
                  Take a Photo
                </Button>
                <LoadingButton
                  size="small"
                  onClick={handleDemographicsUpload}
                  loading={loadingDemographics}
                  disabled={!demographics}
                  endIcon={<SendIcon />}
                  loadingPosition="end"
                  // loadingIndicator="Loading…"
                  variant="contained"
                >
                  <span>Upload Demographics</span>
                </LoadingButton>
              </Stack>
              <Typography variant="h6">
                {demographics ? demographics.name : "No file selected"}
              </Typography>
            </>
          )}
        <Stack direction="row" spacing={2}>
          <Stack direction="column" marginTop="10px" spacing={1}>
            <Paper>
              <img
                src={avatar ? URL.createObjectURL(avatar) : defaultAvatarUrl}
                style={{ width: "200px", padding: "2px", borderRadius: "5px" }}
                alt="avatar"
              />
            </Paper>
            {user &&
              user.role !== MEDICAL_BILLING_PERSON &&
              !painConsultNoteSigned && (
                <Stack direction="row" spacing={1}>
                  <Button component="label" variant="contained">
                    {avatar ? "Change" : "Select Photo"}
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      ref={avatarInput}
                      onChange={handleAvatarChange}
                    />
                  </Button>
                  {avatar && (
                    <Button
                      onClick={handleAvatarRemove}
                      variant="contained"
                      color="warning"
                    >
                      Remove
                    </Button>
                  )}
                </Stack>
              )}
          </Stack>
          <PatientInfo
            patient={patient}
            setPatient={setPatient}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          style={{ marginTop: "30px" }}
        >
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab
              label="Patient Profile"
              {...a11yProps(0)}
              style={{ fontWeight: "bold", fontSize: "18px" }}
            />
            <Tab
              label="Notes"
              {...a11yProps(1)}
              disabled={patient_id ? false : true}
              style={{ fontWeight: "bold", fontSize: "18px" }}
            />
            <Tab
              label="Forms"
              {...a11yProps(2)}
              disabled={patient_id ? false : true}
              style={{ fontWeight: "bold", fontSize: "18px" }}
            />
            <Tab
              label="Attachments"
              {...a11yProps(3)}
              disabled={patient_id ? false : true}
              style={{ fontWeight: "bold", fontSize: "18px" }}
            />
            {user &&
              (user.role === ADMIN || user.role === MEDICAL_ASSISTANT) && (
                <Tab
                  label="Eligibility"
                  {...a11yProps(4)}
                  disabled={patient_id ? false : true}
                  style={{ fontWeight: "bold", fontSize: "18px" }}
                />
              )}
            <Tab
              label="Billing"
              {...a11yProps(5)}
              disabled={patient_id ? false : true}
              style={{ fontWeight: "bold", fontSize: "18px" }}
            />
          </Tabs>
        </Box>
        <div
          role="tabpanel"
          hidden={tabValue !== 0}
          id="simple-tabpanel-0"
          aria-labelledby="simple-tab-0"
        >
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={3}>
              <ContactInfo
                contactInfo={contactInfo}
                setContactInfo={setContactInfo}
                painConsultNoteSigned={painConsultNoteSigned}
              />
              <EmergencyContact
                emergencyContact={emergencyContact}
                setEmergencyContact={setEmergencyContact}
                painConsultNoteSigned={painConsultNoteSigned}
              />
            </Stack>
            {patient.type === PERSONAL_INJURY_PATIENT ? (
              <>
                <Paper style={{ padding: "20px" }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    marginBottom="10px"
                  >
                    Description of Accident:
                  </Typography>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    fullWidth
                    multiline
                    rows={5}
                    value={patient.description_of_accident}
                    onChange={(e) =>
                      setPatient({
                        ...patient,
                        description_of_accident: e.target.value,
                      })
                    }
                    readOnly={
                      (user && user.role === MEDICAL_BILLING_PERSON) ||
                      painConsultNoteSigned
                    }
                  />
                </Paper>
                <InsuranceLawyerInfo
                  insuranceLawyerInfo={insuranceLawyerInfo}
                  setInsuranceLawyerInfo={setInsuranceLawyerInfo}
                  painConsultNoteSigned={painConsultNoteSigned}
                />
              </>
            ) : (
              <InsuranceInformation
                insuranceInformation={insuranceInformation}
                setInsuranceInformation={setInsuranceInformation}
                painConsultNoteSigned={painConsultNoteExist}
              />
            )}
            <Stack direction="row" spacing={3}>
              <Employment
                employment={employment}
                setEmployment={setEmployment}
                painConsultNoteSigned={painConsultNoteSigned}
              />
              {patient.type !== PERSONAL_INJURY_PATIENT && (
                <ConditionRelatedTo
                  conditionRelatedTo={conditionRelatedTo}
                  setConditionRelatedTo={setConditionRelatedTo}
                  painConsultNoteSigned={painConsultNoteSigned}
                />
              )}
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              style={{ justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCancelModalOpen}
              >
                cancel
              </Button>
              {user && user.role !== MEDICAL_BILLING_PERSON && (
                <Button
                  variant="contained"
                  disabled={!submitable}
                  onClick={handleSave}
                >
                  Save
                </Button>
              )}
            </Stack>
          </Stack>
          <Dialog
            fullScreen={fullScreen}
            open={cancelModalOpen}
            onClose={handleCancelModalClose}
          >
            <DialogTitle id="responsive-dialog-title">
              {"Do you really want to cancel?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                If you agree, you will lose all the patient information you
                entered so far.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCancelModalClose}>
                Disagree
              </Button>
              <Button onClick={handleCancel} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog fullScreen={fullScreen} open={successModalOpen}>
            <DialogTitle>{"Success"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                A new patient create successfully!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={goToPatientTable}>
                Go to table
              </Button>
              <Button onClick={createAnotherPatient} autoFocus>
                Create another
              </Button>
              <Button onClick={goToProcedureNote} autoFocus>
                New Pain Consult Note
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog fullScreen={fullScreen} open={doubleInputConfirmModalOpen}>
            <DialogTitle>{"Warning"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                There's a patient with such first name, last name and date of
                birth. Do you really want to create a new patient with such
                info?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                variant="contained"
                color="warning"
                onClick={() => setDoubleInputConfirmModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setDoubleInputConfirmModalOpen(false);
                  handleSave(false);
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div
          role="tabpanel"
          hidden={tabValue !== 1}
          id="simple-tabpanel-1"
          aria-labelledby="simple-tab-1"
        >
          <Notes
            painConsultNoteExist={painConsultNoteExist}
            setPainConsultNoteExist={setPainConsultNoteExist}
            telehealthConsultNoteExist={telehealthConsultNoteExist}
            setTelehealthConsultNoteExist={setTelehealthConsultNoteExist}
            setPainConsultNoteSigned={setPainConsultNoteSigned}
          />
        </div>
        <div
          role="tabpanel"
          hidden={tabValue !== 2}
          id="simple-tabpanel-2"
          aria-labelledby="simple-tab-2"
        >
          <Forms consentForm={consentForm} hipaaForm={hipaaForm} />
        </div>

        <div
          role="tabpanel"
          hidden={tabValue !== 3}
          id="simple-tabpanel-3"
          aria-labelledby="simple-tab-3"
        >
          <Attachments />
        </div>

        {user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT) && (
          <div
            role="tabpanel"
            hidden={tabValue !== 4}
            id="simple-tabpanel-4"
            aria-labelledby="simple-tab-4"
          >
            <Eligibility />
          </div>
        )}
        <div
          role="tabpanel"
          hidden={tabValue !== 5}
          id="simple-tabpanel-5"
          aria-labelledby="simple-tab-5"
        >
          <Billing patient_id={patient_id} />
        </div>
        <Dialog
          open={cameraDialogOpen}
          onClose={handleCameraDialogClose}
          fullScreen
          TransitionComponent={Transition}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          {captureImgSrc ? (
            <img
              src={captureImgSrc}
              alt="webcam"
              style={{ width: 1000, margin: "auto", borderRadius: "10px" }}
            />
          ) : (
            <Webcam
              ref={webcamRef}
              style={{ margin: "auto", borderRadius: "10px" }}
              width={1000}
              screenshotFormat="image/jpeg"
              screenshotQuality={1.0}
              videoConstraints={{ facingMode: selectedCamera }}
            />
          )}
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              autoFocus
              color="error"
              onClick={handleCameraDialogClose}
            >
              Cancel
            </Button>
            {captureImgSrc ? (
              <>
                <Button variant="contained" color="secondary" onClick={retake}>
                  Retake
                </Button>
                <Button variant="contained" onClick={confirmCapture}>
                  Okay
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={toggleCamera}
                >
                  Toggle Camera
                </Button>
                <Button variant="contained" onClick={handleCaptureImage}>
                  Take
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  );
};

export default Patient;
