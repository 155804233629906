import React from "react";
import { Stack, Typography, Switch } from "@mui/material";

const Authorization = ({ hasAuthorization, setHasAuthorization, authorization, authorizationValidityDates }) => {
  return (
    <Stack direction="row">
      <Stack sx={{ width: "250px", marginRight: "10px" }}>
        <Typography variant="h6">Has authorization?</Typography>
        <Switch
          checked={hasAuthorization}
          onChange={() => setHasAuthorization(!hasAuthorization)}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Stack>
      {hasAuthorization && (
        <Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Authorization:
            </Typography>
            <Typography variant="h6">{authorization}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Authorization validity dates:
            </Typography>
            <Typography variant="h6">{authorizationValidityDates}</Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Authorization;
