import React, { useState, useEffect } from "react";
import {
  Typography,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { secureApi } from "../../config";
import OfficeList from "../office_locations/OfficeList";
import AddOfficeLocationModal from "../office_locations/AddModal";
import EditOfficeLocationModal from "../office_locations/EditModal";
import DeleteOfficeLocationModal from "../office_locations/DeleteModal";
import FacilityList from "../facilities/FacilityList";
import AddFacilityModal from "../facilities/AddModal";
import EditFacilityModal from "../facilities/EditModal";
import DeleteFacilityModal from "../facilities/DeleteModal";

const useStyles = makeStyles(() => ({
  bolded: {
    fontWeight: "bold",
  },
}));

const PatientInfo = ({
  patientName,
  dateOfBirth,
  dateOfProcedureNote,
  setDateOfProcedureNote,
  surgeon,
  setSurgeon,
  assistant,
  setAssistant,
  anesthesia,
  setAnesthesia,
  anesthesiologist,
  setAnesthesiologist,
  neuroMonitoring,
  setNeuroMonitoring,
  typeOfFacility,
  setTypeOfFacility,
  nameOfFacility,
  setNameOfFacility,
  officeLocation,
  setOfficeLocation,
}) => {
  const classes = useStyles();

  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState("");
  const [showAddOfficeLocationModal, setShowAddOfficeLocationModal] =
    useState(false);
  const [showEditOfficeLocationModal, setShowEditOfficeLocationModal] =
    useState(false);
  const [showDeleteOfficeLocationModal, setShowDeleteOfficeLocationModal] =
    useState(false);
  const [locationID, setLocationID] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [facility, setFacility] = useState("");
  const [showAddFacilityModal, setShowAddFacilityModal] = useState(false);
  const [showEditFacilityModal, setShowEditFacilityModal] = useState(false);
  const [showDeleteFacilityModal, setShowDeleteFacilityModal] = useState(false);
  const [facilityID, setFacilityID] = useState(null);

  const handleEditOfficeLocationClick = (locationID) => {
    setLocationID(locationID);
    setLocation(locations.filter((loc) => loc._id === locationID)[0]?.location);
    setShowEditModal(true);
  };

  const handleDeleteOfficeLocationClick = (locationID) => {
    setLocationID(locationID);
    setShowDeleteOfficeLocationModal(true);
  };

  const handleEditFacilityClick = (facilityID) => {
    setFacilityID(facilityID);
    setFacility(
      facilities.filter((fac) => fac._id === facilityID)[0]?.facility
    );
    setShowEditFacilityModal(true);
  };

  const handleDeleteFacilityClick = (facilityID) => {
    setFacilityID(facilityID);
    setShowDeleteFacilityModal(true);
  };

  const handleDateChange = (e) => {
    setDateOfProcedureNote(e);
  };

  useEffect(() => {
    secureApi(window.localStorage.getItem("alex-med-token"))
      .get("/office-locations")
      .then((response) => {
        if (response.data["office_locations"]) {
          setLocations(response.data["office_locations"]);
        }
      });

    secureApi(window.localStorage.getItem("alex-med-token"))
      .get("/facilities")
      .then((response) => {
        if (response.data["facilities"]) {
          setFacilities(response.data["facilities"]);
        }
      });
  }, []);

  return (
    <Stack direction="row" style={{ marginTop: "30px" }}>
      <Stack
        direction="column"
        style={{ width: "250px", marginRight: "10px" }}
        spacing={1}
      >
        <Stack direction="column" width="250px" marginTop="20px">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={handleDateChange}
              value={dateOfProcedureNote}
              label="Date of Procedure Note"
            />
          </LocalizationProvider>
        </Stack>
        <TextField
          value={surgeon}
          onChange={(e) => setSurgeon(e.target.value)}
          label="Surgeon"
          multiline
        />
        <TextField
          value={assistant}
          onChange={(e) => setAssistant(e.target.value)}
          label="Assistant"
        />
        <FormControl>
          <InputLabel>Anestheisa</InputLabel>
          <Select
            value={anesthesia}
            label="Anestheisa"
            onChange={(e) => setAnesthesia(e.target.value)}
          >
            <MenuItem value="local">local</MenuItem>
            <MenuItem value="MAC">MAC</MenuItem>
            <MenuItem value="general">general</MenuItem>
          </Select>
        </FormControl>
        <TextField
          value={anesthesiologist}
          onChange={(e) => setAnesthesiologist(e.target.value)}
          label="Anesthesiologist"
        />
        <TextField
          value={neuroMonitoring}
          onChange={(e) => setNeuroMonitoring(e.target.value)}
          label="Neuro-monitoring"
        />
        <FormControl>
          <InputLabel>Type of Facility</InputLabel>
          <Select
            value={typeOfFacility}
            label="Type of Facility"
            onChange={(e) => setTypeOfFacility(e.target.value)}
          >
            <MenuItem value="Medical Office">Medical Office</MenuItem>
            <MenuItem value="Ambulatory Surgical Center">
              Ambulatory Surgical Center
            </MenuItem>
            <MenuItem value="Hospital">Hospital</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Stack direction="column">
        <Typography variant="h6">
          <span className={classes.bolded}>Patient Name: </span>
          {patientName}
        </Typography>
        <Typography variant="h6">
          <span className={classes.bolded}>DOB: </span>
          {dateOfBirth}
        </Typography>
        <Typography variant="h6">
          <span className={classes.bolded}>Date of Procedure: </span>
          {dateOfProcedureNote.format("MM/DD/YYYY")}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography variant="h6" fontWeight="bold">
            Surgeon:{" "}
          </Typography>
          <Stack direction="column">
            {surgeon.split("\n").map((item, index) => (
              <Typography variant="h6" key={index}>
                {item}
              </Typography>
            ))}
          </Stack>
        </Stack>
        <Typography variant="h6">
          <span className={classes.bolded}>Assistant: </span>
          {assistant}
        </Typography>
        <Typography variant="h6">
          <span className={classes.bolded}>Anesthesia: </span>
          {anesthesia}
        </Typography>
        <Typography variant="h6">
          <span className={classes.bolded}>Anesthesiologist: </span>
          {anesthesiologist}
        </Typography>
        <Typography variant="h6">
          <span className={classes.bolded}>Neuro-monitoring: </span>
          {neuroMonitoring}
        </Typography>
        <Typography variant="h6">
          <span className={classes.bolded}>Type of Facility: </span>
          {typeOfFacility}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            Name of Facility:
          </Typography>
          <FacilityList
            facility={nameOfFacility}
            setFacility={(e) => setNameOfFacility(e.target.value)}
            facilities={facilities}
            setShowAddModal={setShowAddFacilityModal}
            handleEditClick={handleEditFacilityClick}
            handleDeleteClick={handleDeleteFacilityClick}
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            Location:
          </Typography>
          <OfficeList
            officeLocation={officeLocation}
            setOfficeLocation={(e) => setOfficeLocation(e.target.value)}
            locations={locations}
            setShowAddModal={setShowAddOfficeLocationModal}
            handleEditClick={handleEditOfficeLocationClick}
            handleDeleteClick={handleDeleteOfficeLocationClick}
          />
        </Stack>
      </Stack>
      <AddOfficeLocationModal
        showAddModal={showAddOfficeLocationModal}
        setShowAddModal={setShowAddOfficeLocationModal}
        location={location}
        setLocation={setLocation}
        locations={locations}
        setLocations={setLocations}
      />
      <EditOfficeLocationModal
        showEditModal={showEditOfficeLocationModal}
        setShowEditModal={setShowEditOfficeLocationModal}
        location={location}
        setLocation={setLocation}
        locations={locations}
        setLocations={setLocations}
        locationID={locationID}
      />
      <DeleteOfficeLocationModal
        showDeleteModal={showDeleteOfficeLocationModal}
        setShowDeleteModal={setShowDeleteOfficeLocationModal}
        locations={locations}
        setLocations={setLocations}
        locationID={locationID}
      />
      <AddFacilityModal
        showAddModal={showAddFacilityModal}
        setShowAddModal={setShowAddFacilityModal}
        facility={facility}
        setFacility={setFacility}
        facilities={facilities}
        setFacilities={setFacilities}
      />
      <EditFacilityModal
        showEditModal={showEditFacilityModal}
        setShowEditModal={setShowEditFacilityModal}
        facility={facility}
        setFacility={setFacility}
        facilities={facilities}
        setFacilities={setFacilities}
        facilityID={facilityID}
      />
      <DeleteFacilityModal
        showDeleteModal={showDeleteFacilityModal}
        setShowDeleteModal={setShowDeleteFacilityModal}
        facilities={facilities}
        setFacilities={setFacilities}
        facilityID={facilityID}
      />
    </Stack>
  );
};

export default PatientInfo;
